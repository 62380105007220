<template>
    <modal
        id="reset"
        :title="$t('modal.reset.heading')"
        type="general"
    >
        <form
            id="password-reset"
            @submit.prevent="reset"
        >
            <div
                class="form-group w100"
                :class="{ error: errors.email }"
            >
                <label>{{ $t('form.fields.label.email_address') }}</label>
                <input
                    v-model="input.email"
                    :placeholder="$t('form.fields.placeholder.email_address')"
                    type="text"
                    class="form-control"
                    name="email"
                >
                <span
                    v-if="errors.email"
                    class="error-message"
                >{{
                    errors.email
                }}</span>
            </div>
            <div
                class="form-group w100 has-icon"
                :class="{ error: errors.password }"
            >
                <label>{{ $t('form.fields.label.password') }}</label>
                <input
                    v-if="!showPassword"
                    v-model="input.password"
                    type="password"
                    class="form-control"
                    name="password"
                >
                <input
                    v-if="showPassword"
                    v-model="input.password"
                    type="text"
                    class="form-control"
                    name="password"
                >
                <img
                    v-if="!showPassword"
                    src="/images/fe_eye.svg"
                    alt="show password icon"
                    @click="showPassword = !showPassword"
                >
                <img
                    v-if="showPassword"
                    src="/images/fe_eye-off.svg"
                    alt="hide password icon"
                    @click="showPassword = !showPassword"
                >
                <span
                    v-if="errors.password"
                    class="error-message"
                >{{
                    errors.password
                }}</span>
            </div>
            <div
                class="form-group w100 has-icon"
                :class="{ error: errors.passwordConfirmation }"
            >
                <label>{{ $t('form.fields.label.password_confirmation') }}</label>
                <input
                    v-if="!showPasswordConfirmation"
                    v-model="input.passwordConfirmation"
                    type="password"
                    class="form-control"
                    name="password-confirmation"
                >
                <input
                    v-if="showPasswordConfirmation"
                    v-model="input.passwordConfirmation"
                    type="text"
                    class="form-control"
                    name="password-confirmation"
                >
                <span
                    v-if="errors.passwordConfirmation"
                    class="error-message"
                >{{
                    errors.passwordConfirmation
                }}</span>

                <img
                    src="/images/fe_eye.svg"
                    alt="show password icon"
                    @click="showPasswordConfirmation = !showPasswordConfirmation"
                >
            </div>
            <div
                class="form-group w100 custom"
                :class="{ error: errors.reset }"
            >
                <span
                    v-if="errors.reset"
                    class="error-message"
                >{{
                    errors.reset
                }}</span>
            </div>
            <b-button
                :disabled="sending"
                variant="primary"
                class="fill lg reset mt-5"
                type="submit"
            >
                {{ $t('modal.reset.heading') }}
            </b-button>
        </form>
    </modal>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';

    import Modal from './Modal.vue';
    export default {
        name: 'ResetPasswordModal',
        components: {
            Modal,
        },
        data() {
            return {
                sending: false,
                showPassword: false,
                showPasswordConfirmation: false,
                input: {
                    email: '',
                    password: '',
                    passwordConfirmation: '',
                },
                errors: {},
            };
        },
        computed: {
            ...mapState('auth', ['inputErrors']),
            ...mapState('general', ['modal']),
            ...mapGetters('auth', ['isLogged']),
        },
        methods: {
            ...mapMutations('general', ['setModal']),
            ...mapActions('auth', ['resetPassword']),
            async reset() {
                this.errors = formValidations(this.input);
                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sending = true;
                await this.resetPassword({
                    email: this.input.email,
                    password: this.input.password,
                    password_confirmation: this.input.passwordConfirmation,
                    token: this.modal.data.token
                });
                this.sending = false;
                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }

                this.closeModal();
                this.$toast.open({
                    message: this.$t('notification.passwordReseted'),
                    type: 'success',
                    duration: 10000,
                });
                if (!this.isLogged) {
                    setTimeout(() => {
                        this.setModal({
                            show: true,
                            type: 'login'
                        });
                    }, 200);
                }
            },
            initialize(){
                this.input.email = this.modal.data.email;
            },
            closeModal() {
                this.setModal({
                    show: false,
                    type: 'reset'
                });
            },
        },
        mounted(){
            this.initialize();
        }
    };
</script>
